import * as Clipboard from "expo-clipboard";
import { Formik, Form } from "formik";
import { Text, Toast } from "native-base";
import { View, useWindowDimensions } from "react-native";
import React from "react";

import { updateEventClip } from "../http/events";
import { EventClipSchema } from "../screens/Schemas";
import AppInput from "../shared/AppInput";
import FormSubtitle from "../shared/FormSubtitle";
import AutoSave from "./AutoSave";
import { minDesktopWidth } from "@/components/globalUtils";

const EventVideoOptionsForm = ({ event }) => {
  const { slug } = event;

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const convertTimeToMilliseconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
  };

  const convertMillisecondsToTime = (milliseconds) => {
    let seconds = Math.floor((milliseconds / 1000) % 60);
    let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  return (
    <Formik
      initialValues={{
        clipFrom: event.clipFrom
          ? convertMillisecondsToTime(event.clipFrom)
          : "",
        clipTo: event.clipTo ? convertMillisecondsToTime(event.clipTo) : "",
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          const clipFromMilliseconds = convertTimeToMilliseconds(
            values.clipFrom
          );
          const clipToMilliseconds = convertTimeToMilliseconds(values.clipTo);
          await updateEventClip({
            slug,
            clipFrom: clipFromMilliseconds,
            clipTo: clipToMilliseconds,
          });
          actions.setSubmitting(false);
        } catch (err) {
          actions.setSubmitting(false);
        }
      }}
      validationSchema={EventClipSchema}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {(f) => (
        <>
          <AutoSave {...f} />

          {(event.event_status === "past" || event.datetime_uploaded) && (
            <View>
              <FormSubtitle>Trim the video</FormSubtitle>

              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <AppInput
                  onChangeText={f.handleChange("clipFrom")}
                  onBlur={f.handleBlur("clipFrom")}
                  value={f.values.clipFrom}
                  placeholder={`00:00:00`}
                  label="From"
                  inputStyle={{ paddingLeft: 0 }}
                  onSubmitOnEnter={() => f.handleSubmit()}
                  autoCapitalize="none"
                  iconRightName={
                    f.values.clipFrom && !f.dirty ? "close-outline" : null
                  }
                  onIconRightAction={() =>
                    f.setFieldValue("clipFrom", "", true)
                  }
                />
                {f.errors.clipFrom && f.touched.clipFrom && (
                  <Text style={{ color: "red" }}>{f.errors.clipFrom}</Text>
                )}

                <Text style={{ fontSize: 12, marginTop: 10 }}>
                  Enter start time in hh:mm:ss format if you want to clip the
                  beginning of the video. For example, if you want to start the
                  video 20 minutes into the live stream, enter "00:20:00"
                </Text>

                {/* <View>
                  <AppInput
                    onChangeText={f.handleChange("clipTo")}
                    onBlur={f.handleBlur("clipTo")}
                    value={f.values.clipTo}
                    placeholder={`00:00:00`}
                    label="To"
                    inputStyle={{ paddingLeft: 0 }}
                    onSubmitOnEnter={() => f.handleSubmit()}
                    autoCapitalize="none"
                  />
                  {f.errors.clipTo && f.touched.clipTo && (
                    <Text style={{ color: "red" }}>{f.errors.clipTo}</Text>
                  )}
                </View> */}
              </View>
            </View>
          )}
        </>
      )}
    </Formik>
  );
};
export default EventVideoOptionsForm;
