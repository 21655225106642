import { Container, Text, Button, Toast } from "native-base";
import React from "react";
import { ActivityIndicator, Platform, View } from "react-native";

import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import AppTitle from "../../shared/AppTitle";
import Bullet from "../../shared/Bullet";
import DashboardContent from "../../shared/DashboardContent";
import { useBuy } from "../../BuyContext";

const BuyCredits = ({ navigation, route }) => {
  const loading = false;
  const auth = useAuth();
  const { broadcaster, legacyPricing } = auth;

  return (
    <Container>
      <AppHeader />
      <DashboardContent>
        {loading && <ActivityIndicator />}
        {!loading && (
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 44,
              paddingHorizontal: 16,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={`Purchase Event Credits`}
              style={{
                textAlign: "left",
                marginBottom: 20,
              }}
            />

            <Text style={{ fontSize: 16, marginBottom: 10 }}>
              Event credits do not expire. Reschedule or cancel for free.
            </Text>
            <Text>Each event includes</Text>

            <View style={{ marginVertical: 20 }}>
              <Bullet condensed>Unlimited viewers</Bullet>
              <Bullet condensed>Unlimited views</Bullet>
              <Bullet condensed>Full Day HD broadcasting</Bullet>
              <Bullet condensed>White-labeling</Bullet>
              <Bullet condensed>Embed on your website</Bullet>
              <Bullet condensed>Unlimited email and chat support</Bullet>
            </View>

            <AppTitle
              text={`Choose number of events`}
              style={{
                textAlign: "left",
                fontSize: 18,
                marginBottom: 10,
              }}
            />

            {!!broadcaster && (
              <CreditsTable
                navigation={navigation}
                legacyPricing={legacyPricing}
              />
            )}
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default BuyCredits;

export const CreditsTable = ({ navigation, legacyPricing }) => {
  const { purchase, getCurrencyPrice, getPricePerItem, getSavingsPercent } =
    useBuy();

  const products = [
    {
      qty: 1,
      priceStripe: "price_1NTkBnCucVzS4gqKqhLTzL0h",
      priceMobile: legacyPricing ? "specialevent50" : "specialeventnew",
      platforms: ["ios", "android", "web"],
    },
    {
      qty: 5,
      priceStripe: "price_1KsUCxCucVzS4gqKi1lc87Ey",
      priceMobile: "package_5",
      platforms: ["ios", "android", "web"],
    },
    {
      qty: 10,
      priceStripe: "price_1JkFFhCucVzS4gqKBxVVdXQA",
      priceMobile: "package_10",
      platforms: ["ios", "web"],
    },
    {
      qty: 25,
      priceStripe: "price_1JkFFhCucVzS4gqKx3nnjHfi",
      priceMobile: "package_25",
      platforms: ["ios", "web"],
    },
    {
      qty: 50,
      priceStripe: "price_1JkFFhCucVzS4gqKlqNF65Ha",
      priceMobile: "package_50",
      platforms: ["web"],
      webOnly: true,
    },
  ];

  return (
    <View style={{ width: "100%", marginTop: 20 }}>
      <View
        style={{
          flexDirection: "row",
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
          paddingBottom: 10,
          marginBottom: 10,
        }}
      >
        <Text style={{ flex: 2, fontWeight: "bold" }}>Credits</Text>
        <Text style={{ flex: 2, fontWeight: "bold", textAlign: "right" }}>
          Price
        </Text>
        <Text
          style={{
            flex: 2,
            fontWeight: "bold",
            textAlign: "right",
            color: "grey",
          }}
        >
          Per Credit
        </Text>
        {Platform.OS === "web" && (
          <Text
            style={{
              flex: 2,
              fontWeight: "bold",
              textAlign: "right",
              color: "grey",
            }}
          >
            You Save
          </Text>
        )}
        <View style={{ flex: 1, minWidth: 50, marginLeft: 10 }} />
      </View>
      {products.map(
        (p) =>
          p.platforms.includes(Platform.OS) && (
            <View
              key={p.priceStripe}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text style={{ flex: 2 }}>{p.qty}</Text>
              <View style={{ flex: 2, alignItems: "flex-end" }}>
                <Text>{getCurrencyPrice(p.priceMobile)}</Text>
              </View>
              <Text style={{ flex: 2, textAlign: "right", color: "grey" }}>
                {getPricePerItem(p.priceMobile)}
              </Text>
              {Platform.OS === "web" && (
                <Text
                  style={{
                    flex: 2,
                    textAlign: "right",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  {getSavingsPercent(p.priceMobile, products[0].priceMobile) !==
                  0
                    ? `${getSavingsPercent(
                        p.priceMobile,
                        products[0].priceMobile
                      )}%`
                    : ""}
                </Text>
              )}
              <Button
                style={{
                  flex: 1,
                  height: 40,
                  justifyContent: "center",
                  marginLeft: 10,
                  paddingHorizontal: 2,
                  minWidth: 50,
                }}
                onPress={() =>
                  purchase({
                    productId: p.priceMobile,
                    onComplete: () => {
                      Toast.show({
                        text: "Thank you!",
                        position: "top",
                        type: "success",
                        style: { marginTop: 30 },
                      });
                      navigation.navigate("Profile");
                    },
                  })
                }
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 12,
                    textAlign: "center",
                    numberOfLines: 1,
                  }}
                >
                  BUY
                </Text>
              </Button>
            </View>
          )
      )}
    </View>
  );
};
