import { Container, View, Footer, Root, Toast } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { DeviceEventEmitter, useWindowDimensions } from "react-native";

import { useFocusEffect } from "@react-navigation/native";

import { useAuth } from "@/components/AuthContext";
import { isWeb, minDesktopWidth } from "@/components/globalUtils";
import {
  fetchEvents,
  subscribeToEventsUpdates,
} from "@/components/http/events";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import AppEventGrid from "@/components/shared/AppEventGrid";
import AppSubtitle from "@/components/shared/AppSubtitle";
import AppTitle from "@/components/shared/AppTitle";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { LargeUnlockButton } from "@/components/shared/Buttons";
import NewTestButton from "@/components/shared/NewTestButton";
import useNow from "@/components/hooks/useNow";
import AppInput from "@/components/shared/AppInput";

const EventList = ({ navigation, route }) => {
  const [loading, setloading] = useState(true);
  const [events, setevents] = useState([]);
  const [search, setsearch] = useState("");

  const now = useNow();
  const { broadcaster, prefer_multiple_tests, setFirstLoad, ...rest } =
    useAuth(); //firstLoad
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const fetch = async () => {
    try {
      console.log("fetching");
      setloading(true);
      const eventsUnsorted = await fetchEvents();

      setevents(
        eventsUnsorted.sort((a, b) =>
          a.event_status === "live"
            ? -1
            : b.event_datetime_utc > a.event_datetime_utc
            ? 1
            : -1
        )
      );
      setloading(false);
    } catch (err) {
      Toast.show({
        text: String(err),
        position: "top",
        type: "danger",
        duration: 5000,
      });
    }
  };

  // const isFocused = useIsFocused();

  useFocusEffect(
    React.useCallback(() => {
      if (broadcaster) {
        fetch();

        return subscribeToEventsUpdates(broadcaster, (updatedEvent) => {
          fetch();
        });
      }
    }, [broadcaster])
  );

  useEffect(() => {
    // Can't subscribe to event delete via appsync because the mutation is missing broadcaster param
    const sub = DeviceEventEmitter.addListener("event_delete", (slug) => {
      setevents([...events.filter((e) => e.slug !== slug)]);
    });
    return () => sub && sub.remove();
  }, [events]);

  const filteredEvents = (events || [])
    .filter((e) => (prefer_multiple_tests ? true : !e.is_test))
    .filter(
      (e) => !search || e.title.toLowerCase().includes(search.toLowerCase())
    );

  const gridRef = useRef(null);

  return (
    <Root>
      <Container>
        <View style={{ flex: 1 }}>
          <DashboardHeader navigation={navigation} />
          {events?.length > 3 && (
            <View
              style={{
                margin: isMobileUX ? 10 : 20,
                marginRight: isMobileUX ? 24 : 40,
                marginLeft: isMobileUX ? 24 : 40,
                marginBottom: 0,
              }}
            >
              <AppInput
                placeholder="Search for an event"
                value={search}
                onChangeText={(v) => setsearch(v)}
              />
            </View>
          )}

          {(filteredEvents.length > 0 || !!search) && (
            <AppEventGrid
              now={now}
              events={filteredEvents}
              navigation={navigation}
              gridRef={gridRef}
            />
          )}

          {filteredEvents.length === 0 && !search && !loading && (
            <View
              style={{
                flex: 1,
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                margin: "15%",
                marginTop: isWeb ? "15%" : 0,
              }}
            >
              {filteredEvents.length === 0 && (
                <>
                  <AppTitle
                    h5
                    onPress={() => {
                      navigation.navigate("CreateEvent");
                    }}
                    text={"Schedule Your Event."}
                  />
                  <AppSubtitle
                    text={
                      "It looks like you have no events at the moment. Create one to see it here."
                    }
                    style={{ textAlign: "center" }}
                    onPress={() => {
                      navigation.navigate("CreateEvent");
                    }}
                  />
                  <LargeUnlockButton
                    onPress={() => {
                      navigation.navigate("CreateEvent");
                    }}
                    text={`Create an Event`}
                    loading={false}
                  />
                </>
              )}
            </View>
          )}
          {loading && filteredEvents.length === 0 && (
            <View
              style={{
                flex: 1,
                justifyContent: "center", //Centered horizontally
                alignItems: "center",
              }}
            >
              <AppActivityIndicator />
            </View>
          )}
        </View>

        <Footer>
          {isMobileUX && (
            <NewTestButton
              style={{
                flex: 1,
                width: "100%",
                height: 62,
                marginHorizontal: 20,
                marginVertical: 10,
              }}
            />
          )}
        </Footer>
      </Container>
    </Root>
  );
};

export default EventList;
