import { Body, Button, H1, Header, Text } from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { useNavigation } from "@react-navigation/native";
import { createTest } from "@/components/http/events";

import { useAuth } from "@/components/AuthContext";

import { Ionicons } from "@expo/vector-icons";

const NewTestButton = ({ style, relatedEvent, title, forceNew = true }) => {
  const navigation = useNavigation();
  const { prefer_multiple_tests, broadcaster, profileLoaded } = useAuth();
  const [testEvent, setTestEvent] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const buttonTitle =
    title || (!!prefer_multiple_tests ? "New Test" : "Test Live");

  useEffect(() => {
    (async () => {
      if (broadcaster && profileLoaded) {
        const e = await createTest(broadcaster, false);
        setTestEvent(e);
      }
    })();
  }, [broadcaster, profileLoaded]);

  const onTestButtonPress = async () => {
    setIsButtonDisabled(true);
    if (profileLoaded && prefer_multiple_tests && forceNew) {
      try {
        // Create a test and navigate
        const newTest = await createTest(broadcaster, true);
        navigation.navigate("HowToStream", {
          relatedEvent,
          event: newTest,
          slug: newTest.slug,
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      // Navigate to preloaded test
      navigation.navigate("HowToStream", {
        relatedEvent,
        event: testEvent,
        slug: testEvent?.slug,
      });
    }
    setIsButtonDisabled(false);
  };

  return testEvent ? (
    <>
      <Button
        rounded
        dark
        block
        style={style}
        onPress={onTestButtonPress}
        disabled={isButtonDisabled}
      >
        <Ionicons name="videocam" size={24} color="#fff" />
        <Text>{buttonTitle}</Text>
      </Button>
    </>
  ) : null;
};

export default NewTestButton;
