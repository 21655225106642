import { Text } from "native-base";
import React, { Suspense } from "react";
import { View } from "react-native";

import EventVideoOptionsForm from "./EventVideoOptionsForm";

const EventVideoUpload = React.lazy(() => import("./EventVideoUpload"));

const ReuploadVideoForm = ({ event }) => {
  return (
    <>
      <Suspense fallback={<Text>Loading...</Text>}>
        <EventVideoUpload event={event} />
      </Suspense>

      {(event.event_status === "past" || event.datetime_uploaded) && (
        <View style={{ marginBottom: 40 }}>
          <EventVideoOptionsForm event={event} />
        </View>
      )}
    </>
  );
};

export default ReuploadVideoForm;
