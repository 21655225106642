import { Root, StyleProvider } from "native-base";
import React, { useEffect } from "react";
import { Alert, Linking, Platform, Text, Dimensions } from "react-native";

import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { CardStyleInterpolators } from "@react-navigation/stack";

import analytics from "@/analytics";
import branch from "@/branchlib";
import getTheme from "@/native-base-theme/components";
import { useAuth } from "@/components/AuthContext";
import { createStackNavigator } from "@/components/Imports";
import { getUrlFromBranch } from "@/components/globalUtils";
import CreateEvent from "@/components/screens/Events/CreateEvent";
import EventEdit from "@/components/screens/Events/EventEdit";
import GoLive from "@/components/screens/Events/GoLive";
import EventList from "@/components/screens/Events/EventList";
import EventSubScreen from "@/components/screens/Events/EventSubScreen.js";
import EventDetails from "@/components/shared/EventDetails";
import EventGuestbook from "@/components/shared/EventGuestbook";
import EventPrivacyForm from "@/components/shared/EventPrivacyForm";

import EventBrandingForm from "@/components/shared/EventBrandingForm";
import EventPersonalizeForm from "@/components/shared/EventPersonalizeForm";
import ReuploadVideoForm from "@/components/shared/ReuploadVideoForm";
import EventEmbedForm from "@/components/shared/EventEmbedForm";
import HowToStream from "@/components/shared/HowToStream";
import SellTickets from "@/components/shared/SellTickets";
import GuestAdd from "@/components/screens/Events/GuestAdd";
import GuestImport from "@/components/screens/Events/GuestImport";
import GuestList from "@/components/screens/Events/GuestList";
import PayWall from "@/components/shared/PayWall";
import TestScreen from "@/components/screens/Events/Test";
import LetsSchedule from "@/components/screens/Events/LetsSchedule";
import ForgotPassword from "@/components/screens/OnBoarding/ForgotPassword";
import ForgotPasswordLinkSent from "@/components/screens/OnBoarding/ForgotPasswordLinkSent";
import PasswordReset from "@/components/screens/OnBoarding/PasswordReset";
import SegmentationForm from "@/components/screens/OnBoarding/SegmentationForm";
import OnboardingNew from "@/components/screens/OnboardingNew";
import SignUpSignIn from "@/components/screens/OnBoarding/SignUpSignIn";
// import SignUpScreen from "@/components/screens/OnBoarding/SignUpScreen";
import BuyCredits from "@/components/screens/Profile/BuyCredits";
import Subscribe from "@/components/screens/Profile/Subscribe";
import Plans from "@/components/screens/Profile/Plans";
import Profile from "@/components/screens/Profile/Profile";
import UpdateEmail from "@/components/screens/Profile/UpdateEmail";
import VideoWall from "@/components/screens/Profile/VideoWall";
import AskReview from "@/components/screens/AskReview";
import { LoadingScreen } from "@/components/LoadingScreen";

export const DefaultAuthenticatedScreen = "events";

const modalOptions = {
  stackPresentation: "modal", // "fullScreenModal",  //TODO: modal is better but tap on iPad navigates back after dismiss
  // formSheet push modal transparentModal containedModal containedTransparentModal fullScreenModal
  headerTransparent: false,
  cardStyle: {
    overflow: "hidden",
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
    vertical: 100,
    margin: 0,
  },
  cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
  animationEnabled: true,
  headerStatusBarHeight: 0,
  gestureEnabled: true,
  cardOverlayEnabled: true,
  detachPreviousScreen: false,
};

const Stack = createStackNavigator();

const isLargeScreen = Dimensions.get("window").width >= 768;

const modalOptions2 = {
  headerShown: false,
  presentation: isLargeScreen ? "transparentModal" : "default",
  headerTransparent: false,
  cardStyle: isLargeScreen
    ? {
        overflow: "hidden",
        borderRadius: 18,
        marginVertical: "3%",
        marginHorizontal: "auto",
        opacity: 1,
        maxWidth: 720,
        height: "94%",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "transparent",
      }
    : {},
  cardStyleInterpolator: isLargeScreen
    ? CardStyleInterpolators.forModalPresentationIOS
    : undefined,
  animationEnabled: !isLargeScreen,
  gestureEnabled: true,
  cardOverlayEnabled: isLargeScreen,
  detachPreviousScreen: false,
};

const MainNavigator = ({ isSignedOut, /*onboarding_done,*/ ...rest }) => {
  const navigation = useNavigation();
  useEffect(() => {
    if (typeof window !== "undefined" && window && window.addEventListener) {
      // Handle keyboard events
      const handleKeyDown = (event) => {
        // Check for backspace or escape keys
        if (event.key === "Backspace" || event.key === "Escape") {
          navigation.goBack();
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [navigation]);

  return (
    <Stack.Navigator
      headerMode={"none"}
      mode="modal"
      name="Root"
      screenOptions={{
        animationEnabled: true,
        // animation: "none",
      }}
      {...rest}
    >
      {isSignedOut && (
        <>
          <Stack.Screen
            name="OnboardingNew"
            component={OnboardingNew}
            options={{
              headerShown: false,
              title: "Welcome to EventLive",
              presentation: "transparentModal", //"transparentModal",
              animationEnabled: false,
              gestureEnabled: true,
            }}
            getId={({ params }) => params?.step}
          />
          <Stack.Screen
            name="SignUpSignIn"
            component={SignUpSignIn}
            options={{
              headerShown: false,
              title: "Sign In - EventLive",
              animationEnabled: false,
            }}
          />

          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPassword}
            options={{
              headerShown: false,
              title: "Forgot Password - EventLive",
            }}
          />
          <Stack.Screen
            name="ForgotPasswordLinkSent"
            component={ForgotPasswordLinkSent}
            options={{
              headerShown: false,
              title: "Password Restore Link Sent - EventLive",
            }}
          />
        </>
      )}
      {!isSignedOut && (
        <>
          {/* {onboarding_done === false && (
            <Stack.Screen
              name="SegmentationForm"
              component={SegmentationForm}
              options={{ headerShown: false, title: "Welcome to EventLive" }}
            />
          )} */}
          <Stack.Screen
            name="EventList"
            component={EventList}
            options={{
              headerShown: false,
              title: "My Events",
              animationEnabled: false,
            }}
          />
          <Stack.Screen
            name="LetsSchedule"
            component={LetsSchedule}
            options={{ headerShown: false, title: "Start with EventLive" }}
          />
          <Stack.Group screenOptions={{ ...modalOptions2 }}>
            <Stack.Screen
              name="EventEdit"
              component={EventEdit}
              options={{
                headerShown: false,
                title: "Event",
              }}
            />
            <Stack.Screen
              name="EventDetails"
              options={{ headerShown: false, title: "Event Details" }}
            >
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={EventDetails}
                  title="Details"
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name="EventPersonalize"
              options={{ title: "Personalize" }}
            >
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={EventPersonalizeForm}
                  title="Personalize"
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="ReuploadVideo">
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={ReuploadVideoForm}
                  title="Reupload Video"
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="HowToStream">
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={HowToStream}
                  title="How to"
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="EventPrivacy" options={{ title: "Privacy" }}>
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={EventPrivacyForm}
                  title="Privacy"
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="EventBranding" options={{ title: "Branding" }}>
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={EventBrandingForm}
                  title="Branding"
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name="EventGuestbook"
              options={{ title: "Guestbook" }}
            >
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={EventGuestbook}
                  title="Guestbook"
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="Embed" options={{ title: "Embed" }}>
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={EventEmbedForm}
                  title="Embed"
                />
              )}
            </Stack.Screen>

            <Stack.Screen name="PayWall" options={{ title: "Buy Now" }}>
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={PayWall}
                  backButtonLite
                  title="Buy Now"
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name="GuestList"
              component={GuestList}
              options={{
                headerShown: false,
                title: "Guest List",
              }}
            />
            <Stack.Screen
              name="GuestImport"
              component={GuestImport}
              options={{
                headerShown: false,
                title: "Import Guests",
              }}
            />
            <Stack.Screen
              name="GuestAdd"
              component={GuestAdd}
              options={{
                headerShown: false,
                title: "Add a Guest",
              }}
            />

            <Stack.Screen
              name="SellTickets"
              options={{ title: "Sell Tickets" }}
            >
              {(props) => (
                <EventSubScreen
                  {...props}
                  contentComponent={SellTickets}
                  title="Sell Tickets"
                />
              )}
            </Stack.Screen>

            <Stack.Screen
              name="Test"
              component={TestScreen}
              options={{ headerShown: false, title: "Test EventLive" }}
            />
          </Stack.Group>

          <Stack.Screen
            name="CreateEvent"
            component={CreateEvent}
            options={{ headerShown: false, title: "Schedule Event" }}
          />

          <Stack.Screen
            name="BuyCredits"
            component={BuyCredits}
            options={{
              // ...modalOptions,
              headerShown: false,
              title: "Buy Streaming Credits",
            }}
          />

          <Stack.Screen
            name="Subscribe"
            component={Subscribe}
            options={{
              ...modalOptions,
              headerShown: false,
              title: "Subscribe to EventLive Unlimited",
            }}
          />

          <Stack.Screen
            name="Plans"
            component={Plans}
            options={{
              ...modalOptions,
              headerShown: false,
              title: "EventLive Plans",
            }}
          />

          <Stack.Screen
            name="VideoWall"
            component={VideoWall}
            options={{
              ...modalOptions,
              headerShown: false,
              title: "Video Wall",
            }}
          />
          <Stack.Screen
            name="Profile"
            component={Profile}
            options={{ headerShown: false, title: "Profile" }}
          />
          <Stack.Screen
            name="UpdateEmail"
            component={UpdateEmail}
            options={{
              // ...modalOptions,
              headerShown: false,
              title: "Update Email",
            }}
          />
          {/* Duplicated here  */}
          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPassword}
            options={{
              headerShown: false,
              title: "Forgot Password - EventLive",
            }}
          />
          <Stack.Screen
            name="ForgotPasswordLinkSent"
            component={ForgotPasswordLinkSent}
            options={{
              headerShown: false,
              title: "Password Restore Link Sent - EventLive",
            }}
          />
        </>
      )}
      <Stack.Screen
        name="AskReview"
        component={AskReview}
        options={{ headerShown: false, title: "Help Us Grow" }}
      />

      <Stack.Screen
        name="GoLive"
        component={GoLive}
        options={{ headerShown: false, title: "Go Live" }}
      />

      <Stack.Screen
        name="PasswordReset"
        component={PasswordReset}
        options={{
          headerShown: false,
          title: "Set a New Password - EventLive",
        }}
      />
    </Stack.Navigator>
  );
};

const AppRouting = React.forwardRef((props, ref) => {
  const { isSignedOut, isLoading, signInWithTokens } = useAuth();

  const linking = {
    prefixes: [
      "https://broadcaster.eventlive.pro",
      "http://localhost:19006",
      "eventlive://",
    ],

    async getInitialURL() {
      if (Platform !== "web") {
        const params = await branch.getLatestReferringParams();
        return getUrlFromBranch(params);
      } else {
        const url = await Linking.getInitialURL();
        if (url != null) {
          return url;
        }
      }
    },

    subscribe(listener) {
      const onReceiveURL = ({ url }) => {
        console.warn("ONRECEIVEURL", url);
        listener(url);
      };

      // Listen to incoming links from deep linking
      const l = Linking.addEventListener("url", onReceiveURL);

      let branchUnsubscribe;

      if (Platform !== "web") {
        console.log("BRANCH SUBSCRIBE");
        branchUnsubscribe = branch.subscribe(async ({ error, params }) => {
          // console.warn("branch event");

          if (params && params["+clicked_branch_link"]) {
            analytics.track("Open Link", params);
          }

          if (error) {
            console.error("Error from Branch: " + error);
            return;
          }

          if (params["+non_branch_link"]) {
            // const nonBranchUrl = params["+non_branch_link"];
            // Route non-Branch URL if appropriate.
            return;
          }

          if (!params["+clicked_branch_link"]) {
            // Indicates initialization success and some other conditions.
            // No link was opened.
            return;
          }

          // A Branch link was opened
          // console.log("CANONICAL", params.$canonical_url);

          if (params.idToken && params["+match_guaranteed"]) {
            /// AND IF +match_guaranteed
            // console.log("Got token in params");
            //$deeplink_path'/event/test-event',
            //$canonical_url

            // TODO: Analytics track this event(!) as Sign In mobile
            await signInWithTokens({
              idToken: params.idToken,
              refreshToken: params.refreshToken,
              accessToken: params.accessToken,
              username: params.username,
              // after: params.$canonical_url,
            });
          }

          const url = getUrlFromBranch(params);

          console.warn("got url from branch", url);

          //FILTER PROPERTIES
          // APPEND TO CANONICAL
          listener(url);
        });
      }

      return () => {
        // Clean up the event listeners
        // Linking.removeEventListener("url", onReceiveURL);
        // console.log("BRANCH UNSUBSCRIBE");
        if (l && l.remove) {
          l.remove();
        }
        if (branchUnsubscribe) {
          branchUnsubscribe();
        }
      };
    },
    config: {
      screens: {
        // Welcome: "welcome",
        SignUpSignIn: "signin",
        ForgotPassword: "forgot",
        ForgotPasswordLinkSent: "forgotsent",
        PasswordReset: "set_password",
        LetsSchedule: {
          path: "start",
        },
        Test: {
          path: "getapp/:slug?",
        },
        EventList: {
          path: "list",
        },
        SegmentationForm: {
          path: "welcome",
        },
        OnboardingNew: {
          path: "onboarding/:step?",
        },
        Profile: {
          path: "profile",
        },
        UpdateEmail: {
          path: "profile/update_email",
        },
        BuyCredits: {
          path: "profile/get_credits",
        },
        Plans: {
          path: "profile/plans",
        },
        Subscribe: {
          path: "profile/subscribe",
        },
        VideoWall: {
          path: "profile/videowall",
        },
        EventEdit: {
          path: "event/:slug?",
        },
        PayWall: {
          path: "buy/:slug",
        },
        SellTickets: {
          path: "sell/:slug",
        },
        CreateEvent: {
          path: "create",
        },
        GoLive: {
          path: "stream/:stream_key?",
        },
        // EventSettings: {
        //   path: "event/:slug/settings",
        // },
        // EventSettingsAlt: {
        //   path: "settings/:slug?",
        // },
        GuestList: {
          path: "event/:slug/guests",
        },
        GuestImport: {
          path: "event/:slug/guests/import",
        },
        // SetPassword: {
        //   path: "set_password",
        // },
        GuestAdd: {
          path: "event/:slug/guests/invite",
        },
      },
    },
  };

  const routeNameRef = React.useRef();

  return (
    <StyleProvider style={getTheme()}>
      <Root>
        {isLoading && <LoadingScreen />}
        {!isLoading && (
          <NavigationContainer
            linking={linking}
            ref={ref}
            fallback={<LoadingScreen />}
            onReady={() => {
              const current = ref.current.getCurrentRoute().name;
              analytics.page(current);
              return (routeNameRef.current = current);
            }}
            onStateChange={async (state) => {
              const previousRouteName = routeNameRef.current;
              const currentRouteName = ref.current.getCurrentRoute().name;

              if (previousRouteName !== currentRouteName) {
                analytics.page(currentRouteName);
              } else {
                // Track Onboarding steps
                const lastRoute = state.routes[state.routes.length - 1];
                // console.log("LAST ROUT", lastRoute);
                if (lastRoute?.name === "OnboardingNew") {
                  analytics.page("Onboarding_" + lastRoute?.params?.step);
                }
              }

              // Save the current route name for later comparison
              routeNameRef.current = currentRouteName;
            }}
          >
            <MainNavigator
              isSignedOut={isSignedOut}
              // onboarding_done={onboarding_done}
            />
          </NavigationContainer>
        )}
      </Root>
    </StyleProvider>
  );
});

export default AppRouting;
