import { useFormik } from "formik";
import moment from "moment-timezone";
import React, { useImperativeHandle, useMemo, useState } from "react";
// import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { View, Platform, Pressable } from "react-native";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { Toast, Text } from "native-base";

import { timezones, eventTypes, makeSlug } from "@/components/globalUtils";
import { createEvent } from "@/components/http/events";
import { CreateEventSchema } from "@/components/screens/Schemas";
import AppDatePicker from "@/components/shared/AppDatePicker";
import AppInput from "@/components/shared/AppInput";
import AppPicker from "@/components/shared/AppPicker";
import MultiDaySelector from "@/components/shared/MultiDaySelector";
import { TextLink } from "@/components/shared/TextLink";

import OnboardingButton from "@/components/shared/OnboardingButton";

const CreateEventForm = (props, ref) => {
  const navigation = useNavigation();

  const f = useFormik({
    initialValues: {
      event_type: "wedding",
      event_timezone: moment.tz.guess(),
      event_datetime: null,
      days: 1,
    },
    validationSchema: CreateEventSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        // Create an event
        const event = await createEvent(values);
        // navigation.replace("EventEdit", { slug: event.slug, event });

        navigation.dispatch(
          CommonActions.reset({
            index: 1, // The index corresponds to the active route in the routes array (EventEdit in this case)
            routes: [
              { name: "EventList" }, // This will be at the bottom of the stack
              { name: "EventEdit", params: { slug: event.slug, event } }, // This will be on top
              { name: "PayWall", params: { slug: event.slug, event } },
            ],
          })
        );
      } catch (err) {
        actions.setSubmitting(false);
        Toast.show({
          text: String(err),
          position: "top",
          type: "danger",
          duration: 5000,
        });
      }
    },
  });

  useImperativeHandle(ref, () => ({
    isReadyToSave: () => {
      return !!f.dirty && !f.isSubmitting;
    },
  }));

  // const updateEventType = useMemo(() => f.handleChange("event_type"), []);
  const updateTimeZone = useMemo(() => f.handleChange("event_timezone"), []);
  const updateEventType = useMemo(() => f.handleChange("event_type"), []);
  const [customizeTZ, setcustomizeTZ] = useState(false);

  return (
    <>
      <View style={{ flex: 1 }}>
        <AppInput
          onChangeText={(v) => {
            if (!f.touched.alias) {
              f.setFieldValue("alias", makeSlug(v), true);
            }
            f.handleChange("title")(v);
          }}
          onBlur={f.handleBlur("title")}
          value={f.values.title}
          placeholder="Event Title *"
          onSubmitOnEnter={() => f.handleSubmit()}
          errorMessage={
            f.errors.title && f.touched.title ? f.errors.title : null
          }
        />

        {(f.touched.alias || !!f.values.title || !!f.values.alias) && (
          <AppInput
            onChangeText={f.handleChange("alias")}
            onBlur={f.handleBlur("alias")}
            value={f.values.alias}
            placeholder={`your-event-link`}
            label="evt.live/"
            labelStyle={{
              textAlign: "right",
              paddingRight: 0,
              paddingTop:
                Platform.OS === "web" ? 0 : Platform.OS === "android" ? -4 : 4,
              color: "#2e3f52",
            }}
            inputStyle={{ paddingLeft: 0 }}
            autoCapitalize="none"
            errorMessage={!!f.errors.alias ? f.errors.alias : ""}
            iconRightName={
              !f.errors.alias ? "checkmark-outline" : "close-outline"
            }
            iconRightColor={!f.errors.alias ? "green" : "red"}
            onIconRightAction={() => {}}
          />
        )}

        <AppPicker
          label="Event Type"
          placeholder="Event Type"
          values={eventTypes.map((t) => ({ name: t.label, value: t.value }))}
          selectedValue={f.values.event_type}
          onValueChange={updateEventType}
        />

        <AppDatePicker
          selected={f.values.event_datetime}
          selectedValue={f.values.event_datetime}
          onChange={(value) => {
            f.setFieldValue("event_datetime", value);
          }}
          popperPlacement="top"
          onBlur={f.handleBlur("event_datetime")}
          placeholderText="Event Date and Time *"
          errorMessage={
            f.errors.event_datetime && f.touched.event_datetime
              ? "Please provide event's local Date and Time"
              : null
          }
        />

        {!customizeTZ && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginVertical: 10,
            }}
          >
            <Text>Timezone: {f.values.event_timezone} </Text>
            <TextLink
              text={"Change"}
              onPress={() => {
                setcustomizeTZ(true);
              }}
              textStyle={{}}
            />
          </View>
        )}

        {customizeTZ && (
          <AppPicker
            label="Timezone"
            placeholder="Time Zone"
            values={timezones}
            selectedValue={f.values.event_timezone}
            onValueChange={updateTimeZone}
          />
        )}

        <MultiDaySelector f={f} questionStyle={{ textAlign: "right" }} />
      </View>

      <OnboardingButton
        onPress={f.handleSubmit}
        disabled={f.isSubmitting}
        text={"Schedule Event"}
      />
    </>
  );
};

export default React.forwardRef(CreateEventForm);
