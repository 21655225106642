import * as Linking from "expo-linking";
import moment from "moment-timezone";
import {
  Button,
  Container,
  Text,
  Footer,
  Content,
  Icon,
  List,
  ListItem,
  Left,
  Root,
  Toast,
  Right,
} from "native-base";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  Platform,
  StyleSheet,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import ScalableImage from "react-native-scalable-image";
import * as Clipboard from "expo-clipboard";

import { StyleConstants } from "@/components/globalStyle";
import { useAuth } from "@/components/AuthContext";
import {
  formatEventDate,
  isWeb,
  minDesktopWidth,
  makeUrl,
  makeDownloadUrl,
} from "@/components/globalUtils";
import { openStreamWindow } from "@/components/Utils";
import analytics from "@/analytics";

import useNow from "@/components/hooks/useNow";
import AppInput from "@/components/shared/AppInput";

import useSubscribeToEventUpdates from "@/components/hooks/useSubscribeToEventUpdates";
import { unlockWithCredit } from "@/components/http/events";
import AppHeader, { onBackPressed } from "@/components/shared/AppHeader";
import { Ionicons } from "@expo/vector-icons";

import { LargeUnlockButton, LiveIndicator } from "@/components/shared/Buttons";
import GoLiveButton from "@/components/shared/GoLiveButton";
// import WaysToStreamTips from "@/components/shared/WaysToStreamTips";
// import { useShare } from "@/components/hooks/useShare";
import useSharePress from "@/components/hooks/useSharePress";
import NewTestButton from "@/components/shared/NewTestButton";
let StoreReview;
if (Platform.OS !== "web") {
  StoreReview = require("react-native-store-review");
}

const styles = StyleSheet.create({
  eventDetailsSidebar: {
    // height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "center",
    marginLeft: 24,
    flex: 1,
    flexGrow: 1,
  },
  optionsContainerMobile: {
    width: "100%",
    paddingHorizontal: 24,
    // flex: 1,
    // flexGrow: 1,
    // flexDirection: "column",
    // justifyContent: "space-around",
    // alignItems: "center",
    paddingBottom: 20,
    // alignContent: 'center',
  },
  dateTime: {
    fontSize: 14,
    fontWeight: "800",
    fontStyle: "normal",
    textAlign: "left",
    letterSpacing: isWeb ? "normal" : null,
    lineHeight: isWeb ? "normal" : null,
    flexGrow: 0,
    marginVertical: 5,
    marginRight: 10,
  },
  buttonRow: {
    // flex: 1,
    // flexShrink: 1,
    alignContent: "center",
    alignItems: "center",
    // width: "100%",
    justifyContent: "center",
    marginTop: 20,
  },
  linkAndShare: {
    flex: 1,
    width: "100%",
  },
  nobottomMargin: { marginBottom: 0 },
});

const EventEdit = ({ route, navigation }) => {
  const { slug, event: initialEvent } = route.params || {};

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const [unlockbuttonloading, setunlockbuttonloading] = useState(false);

  // TODO: Update this based on is_published & if unlock param changed in props
  const {
    broadcaster,
    credits,
    subscription_access_expiry_datetime,
    refetchProfile,
    custom_domain,
    getOpenAppFromWebUrl,
    account_type,
  } = useAuth();

  const { event, loading, error } = useSubscribeToEventUpdates(
    initialEvent?.title ? initialEvent : { broadcaster, slug }
  );

  const [loadingGoLive, setLoadingGoLive] = useState(false);

  // useFocusEffect
  // const [event, setEvent] = useState(initialEvent);

  useEffect(() => {
    if (error || !slug) {
      navigation.navigate("EventList");
    }
  }, [error, slug]);

  const {
    event_cover_path,
    title,
    alias,
    stream_key,
    is_published,
    is_test,
    event_status,
    days = 1,
    days_paid,
    event_datetime_utc,
    event_timezone,
    event_window_end_datetime_utc,
    event_window_start_datetime_utc,
  } = event;

  const daysToPay = is_published ? 0 : days - days_paid;

  const eventDate = formatEventDate(event_datetime_utc, event_timezone);

  const url = makeUrl(event, custom_domain);
  const downloadUrl = makeDownloadUrl(event, custom_domain);

  const linkPress = useCallback(() => {
    analytics.track("Preview Link");
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else Linking.openURL(url);
  }, [url]);

  const downloadPress = useCallback(() => {
    if (Platform.OS === "web") {
      window.open(downloadUrl, "_blank");
    } else {
      Linking.openURL(downloadUrl);
      StoreReview.requestReview();
    }
  }, [downloadUrl]);

  const downloadAvailable = is_published && !is_test && event_status === "past";

  const goLive = useCallback(async () => {
    if (Platform.OS === "web") {
      navigation.navigate("HowToStream", { event, slug });
    } else {
      if (loadingGoLive) return;
      setLoadingGoLive(true);
      await openStreamWindow(event);
      // navigation.navigate("AskReview");
      setLoadingGoLive(false);
    }
  }, [
    slug,
    stream_key,
    title,
    event_datetime_utc,
    event_window_end_datetime_utc,
    event_window_start_datetime_utc,
    event_status,
  ]);

  const maxWidth = 640;
  const margin = 24;

  const imageWidth = Math.min(
    Dimensions.get("window").width - margin * 2,
    maxWidth - margin * 2
  );
  // TODO: max-width

  const now = useNow();
  const isAfter = now.isAfter(event_window_end_datetime_utc);

  const subscriptionActive =
    subscription_access_expiry_datetime > moment().utc().format();

  const { sharePress, ShareModal } = useSharePress(
    `You're invited to watch ${event.title}`,
    url
  );

  const copy = (url) => {
    Clipboard.setString(url);

    Toast.show({
      text: "Copied",
      position: "top",
      type: "success",
      duration: 2000,
    });
  };

  const menu = [
    ...(!isAfter
      ? [
          {
            title: "How to Go Live",
            icon: "ios-videocam",
            screen: "HowToStream",
          },
        ]
      : []),
    { title: "Guest List & Invite", icon: "ios-people", screen: "GuestList" },
    {
      title: "Event Details",
      icon: "ios-information-circle",
      screen: "EventDetails",
    },
    ...(!isAfter
      ? [
          {
            title: "Personalize Event Page",
            icon: "ios-color-palette",
            screen: "EventPersonalize",
          },
        ]
      : [
          {
            title: "Reupload Video",
            icon: "ios-cloud-upload",
            screen: "ReuploadVideo",
          },
        ]),

    // {
    //   title: "Change Cover Image",
    //   icon: "ios-image",
    //   screen: "EventPersonalize",
    // },
    // {
    //   title: "Upload Video",
    //   icon: "ios-cloud-upload",
    //   screen: "EventPersonalize",
    // },
    // { title: "Edit link", icon: "ios-link", screen: "EventPersonalize" },
    {
      title: event.prefer_chat ? "Chat" : "Guestbook",
      icon: event.prefer_chat ? "ios-chatbubbles" : "ios-book",
      screen: "EventGuestbook",
    },
    { title: "Privacy Settings", icon: "ios-shield", screen: "EventPrivacy" },
    ...(account_type === "business"
      ? [
          {
            title: "Event Branding",
            icon: "ios-brush",
            screen: "EventBranding",
          },
        ]
      : []),

    { title: "Sell Tickets", icon: "ios-card", screen: "SellTickets" },
    { title: "Embed", icon: "ios-code", screen: "Embed" },
  ];

  return (
    <Container>
      <AppHeader
        leftButtonComponent={
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => onBackPressed(navigation, route)}
          >
            <Ionicons name="ios-close-outline" size={30} color="#F84123" />
          </TouchableOpacity>
        }
        hasTitle={false}
        hasRightButton={false}
      />

      <Content
        contentContainerStyle={{
          width: "100%",
          maxWidth,
          alignSelf: "center",
          paddingHorizontal: 24,
        }}
      >
        <ShareModal />
        {!title && <AppActivityIndicator />}
        {title && (
          <>
            <Text
              style={{
                fontFamily: StyleConstants.defaultTitleFont,
                fontWeight: "bold",
                textAlign: "left",
                maxWidth: imageWidth,
                fontSize: isMobileUX ? 27 : 34,
                flexShrink: 1,
                flexWrap: "wrap",
              }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {title}{" "}
              {event_status === "live" && (
                <LiveIndicator style={{ marginRight: 10 }} />
              )}
            </Text>

            {!is_test && (
              <Text style={styles.dateTime}>
                {days > 1 && `${days}-Day Event: `}
                {eventDate}
                {days > 1 &&
                  ` – ${formatEventDate(
                    event_window_end_datetime_utc,
                    event_timezone
                  )}`}
              </Text>
            )}

            <View>
              <ScalableImage
                onPress={() => {
                  navigation.navigate("EventPersonalize", {
                    event,
                    slug,
                  });
                }}
                style={{
                  borderRadius: 5,
                }}
                // height={isMobileUX ? undefined : Math.min(Dimensions.get("window").height - 180, 380)}
                height={undefined}
                width={imageWidth}
                source={{ uri: event_cover_path }}
              />
            </View>

            <View style={{ marginTop: 20 }}>
              <View style={{ flex: 1, flexDirection: "row" }}>
                <AppInput
                  value={url}
                  style={{ flex: 1 }}
                  inputStyle={{ paddingVertical: 10 }}
                  autoCapitalize="none"
                  iconRightName={"ios-share-outline"}
                  onIconRightAction={() => {
                    // copy(url);
                    sharePress();
                  }}
                  showSoftInputOnFocus={false}
                  onFocus={(event) => {
                    event.preventDefault();
                    copy(url);
                  }}
                />
                <Button
                  onPress={linkPress}
                  style={{
                    marginLeft: 10,
                    marginVertical: 5,
                    borderRadius: 16,
                    backgroundColor: "#FFF6F5",
                    height: 52,
                  }}
                >
                  <Text>
                    <Icon
                      name="eye-outline"
                      type="Ionicons"
                      style={{ color: "#F84123" }}
                    />
                  </Text>
                </Button>
              </View>

              <View
                style={{
                  maxWidth,
                  width: "100%",
                  alignSelf: "center",
                  display: "flex",
                  marginVertical: 10,
                }}
              >
                {!isAfter && (
                  <GoLiveButton
                    event={event}
                    goLive={goLive}
                    style={{ marginVertical: 10 }}
                  />
                )}

                {downloadAvailable && (
                  <Button
                    rounded
                    block
                    dark
                    onPress={downloadPress}
                    style={{ height: 62, marginVertical: 10 }}
                  >
                    <Text>Download</Text>
                  </Button>
                )}

                {!is_published &&
                  (credits < daysToPay || !daysToPay) &&
                  !subscriptionActive && (
                    <View>
                      <NewTestButton
                        style={{ marginVertical: 10, height: 62 }}
                        relatedEvent={event}
                        forceNew={false}
                        title="Test Live"
                      />
                      {/* 
                      <Button
                        rounded
                        dark
                        block
                        style={{ marginVertical: 10, height: 62 }}
                        onPress={() => {
                          navigation.navigate("HowToStream", {
                            event,
                            slug: event?.slug,
                          });
                        }}
                      >
                        <Ionicons name="videocam" size={24} color="#fff" />
                        <Text>Test Live</Text>
                      </Button> */}

                      <LargeUnlockButton
                        text="Buy Now"
                        style={{ marginVertical: 10 }}
                        onPress={() => {
                          navigation.navigate("PayWall", {
                            event,
                            slug: event.slug,
                          });
                        }}
                      />
                    </View>
                  )}

                {!is_published &&
                  ((credits >= daysToPay && daysToPay > 0) ||
                    subscriptionActive) && (
                    <LargeUnlockButton
                      style={styles.nobottomMargin}
                      loading={unlockbuttonloading}
                      onPress={async () => {
                        setunlockbuttonloading(true);
                        await unlockWithCredit(slug, daysToPay);
                        await refetchProfile();
                        setunlockbuttonloading(false);
                      }}
                      text={`${
                        days_paid > 0 ? `${days}-day event: ` : ""
                      } Unlock with ${daysToPay}${
                        days_paid > 0 ? " more " : " "
                      }credit${daysToPay > 1 ? "s" : ""}`}
                    />
                  )}
              </View>
            </View>

            <List style={{ marginBottom: 40 }}>
              {menu.map((item) => (
                <ListItem
                  noIndent
                  key={item.title}
                  onPress={() =>
                    navigation.navigate(item.screen || "EventEdits", {
                      event,
                      slug,
                    })
                  }
                >
                  <Left>
                    <Icon
                      name={item.icon}
                      style={{ marginRight: 10, paddingVertical: 6 }}
                    />
                    <Text style={{ paddingVertical: 6 }}>{item.title}</Text>
                  </Left>
                  <Right>
                    <Icon name="arrow-forward" style={{ paddingVertical: 6 }} />
                  </Right>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Content>
    </Container>
  );
};

export default EventEdit;
