import { Content } from "native-base";
import React from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";

import { isWeb } from "../globalUtils";

export const styles = StyleSheet.create({
  dashboardContent: {
    alignContent: "space-around",
    //TODO: fix:  on android these parameters cause problems
    // flexGrow: isWeb ? 1: null,
    // flex: isWeb ? 1: null,

    // flex: 1,
    flexGrow: 1,

    alignSelf: "center",
    justifyContent: "center",
    maxWidth: 1294,
    width: "100%",
  },
});

export const DashboardContentStatic = ({ children, isTopAligned }) => {
  return (
    <SafeAreaView
      style={[
        styles.dashboardContent,
        {
          justifyContent: isTopAligned ? "flex-start" : "center",
        },
      ]}
    >
      {children}
    </SafeAreaView>
  );
};

export const DashboardContentScrollable = ({ children, isTopAligned }) => {
  return (
    <Content
      contentContainerStyle={[
        styles.dashboardContent,
        {
          justifyContent: isTopAligned ? "flex-start" : "center",
        },
      ]}
      showsVerticalScrollIndicator={false}
      style={{
        flex: 1,
      }}
    >
      {children}
    </Content>
  );
};

const DashboardContent = ({
  isGrid = false,
  isTopAligned = false,
  ...props
}) => {
  return (
    <>
      {isGrid && !isWeb ? (
        <DashboardContentStatic isTopAligned={isTopAligned} {...props} />
      ) : (
        <DashboardContentScrollable isTopAligned={isTopAligned} {...props} />
      )}
    </>
  );
};

export default DashboardContent;
