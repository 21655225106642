import { Body, Button, H1, Header, Text } from "native-base";
import React from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { isWeb, minDesktopWidth } from "@/components/globalUtils";
import NewTestButton from "../shared/NewTestButton";
import { useAuth } from "@/components/AuthContext";
import moment from "moment";

const styles = StyleSheet.create({
  button: {
    height: 48,
    marginLeft: 9,
    paddingLeft: 20,
    paddingRight: 20,
  },
  mobileButton: {
    height: 32,
    marginLeft: 9,
  },
  icon: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  mobileIcon: {
    paddingVertical: 4,
  },
  buttonText: { padding: 0, fontSize: 15, fontWeight: "500" },
  pill: {
    // paddingHorizontal: 12,
    // paddingVertical: 14,
    borderRadius: 16,
  },
  pillText: {
    color: "white",
    fontWeight: "bold",
  },
});

export const AccountTypePill = ({ credits, subActive, onPress, style }) => {
  const pillStyle = {
    ...styles.pill,
    backgroundColor: subActive ? "#FFD700" : "#008080",
    backgroundImage: subActive
      ? "linear-gradient(45deg, #FFD700, #FFA500)"
      : "linear-gradient(45deg, #008080, #20B2AA)",
    ...style,
  };

  return (
    <Button style={pillStyle} onPress={onPress}>
      <Text style={[styles.pillText]}>
        {subActive
          ? "Unlimited"
          : `Pay As You Go [${credits} ${
              credits === 1 ? "Credit" : "Credits"
            }]`}
      </Text>
    </Button>
  );
};

const DashboardHeader = ({ navigation }) => {
  const {
    credits,
    subscription_status,
    subscription_access_expiry_datetime,
    account_type,
  } = useAuth();

  const subActive =
    subscription_access_expiry_datetime > moment().utc().format();

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const createEvent = () => {
    navigation.navigate("CreateEvent");
  };

  const goToBuyCredits = () => {
    navigation.navigate("Plans");
  };

  return (
    <>
      <Header
        style={{
          width: "100%",
          paddingTop: 0,
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: "white",
        }}
      >
        <Body style={{ flex: 1, width: "100%" }}>
          <View
            style={{
              width: "100%",
              marginTop: isWeb ? 20 : 8,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <H1
                style={{
                  flexGrow: 0,
                  flexShrink: 0,
                  fontSize: isMobileUX ? 24 : 32,
                  fontWeight: "800",
                  paddingRight: 6,
                  paddingLeft: isMobileUX ? 0 : 20,
                }}
              >
                My Events
              </H1>
              <TouchableOpacity activeOpacity={1} onPress={createEvent}>
                <Ionicons
                  name="add-circle"
                  size={30}
                  color="#F84123"
                  style={{ flex: 0, alignSelf: "center" }}
                />
              </TouchableOpacity>
            </View>

            {!isMobileUX && (
              <NewTestButton
                style={isMobileUX ? styles.mobileButton : styles.button}
              />
            )}

            {!isMobileUX && account_type === "business" && (
              <AccountTypePill
                credits={credits}
                subActive={subActive}
                onPress={goToBuyCredits}
                style={{ marginLeft: 10 }}
              />
            )}

            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {
                navigation.navigate("Profile");
              }}
            >
              <Ionicons
                name="person-outline"
                size={24}
                color="#3f5b7a"
                style={isMobileUX ? styles.mobileIcon : styles.icon}
              />
            </TouchableOpacity>
          </View>
        </Body>
      </Header>
    </>
  );
};

export default DashboardHeader;
