import { Container, Button, Text, View, Segment } from "native-base";
import React, { useState } from "react";
import { ActivityIndicator, Platform, Linking, StyleSheet } from "react-native";

import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import AppTitle from "../../shared/AppTitle";
import Bullet from "../../shared/Bullet";
import DashboardContent from "../../shared/DashboardContent";
import { CreditsTable } from "./BuyCredits";
import { SubscriptionTable } from "./Subscribe";

const Plans = ({ navigation, route }) => {
  const [loading, setloading] = useState(false);
  const [activeTab, setActiveTab] = useState("credits");
  const auth = useAuth();
  const { broadcaster, email, legacyPricing } = auth;

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Container>
      <AppHeader />
      <DashboardContent isTopAligned>
        {loading && <ActivityIndicator />}
        {!loading && (
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 44,
              paddingHorizontal: 16,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={`EventLive Plans`}
              style={{
                textAlign: "left",
                marginBottom: 20,
              }}
            />

            <Segment>
              <Button
                first
                active={activeTab === "credits"}
                onPress={() => toggleTab("credits")}
              >
                <Text>Event Credits</Text>
              </Button>
              <Button
                last
                active={activeTab === "unlimited"}
                onPress={() => toggleTab("unlimited")}
              >
                <Text>Unlimited Streaming</Text>
              </Button>
            </Segment>
            <View style={{ marginVertical: 40 }}>
              {activeTab === "credits" && (
                <CreditsTable
                  navigation={navigation}
                  legacyPricing={legacyPricing}
                />
              )}
              {activeTab === "unlimited" && (
                <>
                  <SubscriptionTable
                    navigation={navigation}
                    legacyPricing={legacyPricing}
                    email={email}
                    broadcaster={broadcaster}
                  />
                </>
              )}
            </View>

            {activeTab === "unlimited" && (
              <View style={{ marginBottom: 20 }}>
                <AppTitle
                  text="Unlimited Subscription Includes"
                  style={{
                    textAlign: "left",
                    fontSize: 18,
                    marginBottom: 20,
                  }}
                />
                <View>
                  <Bullet condensed>Advanced Event Analytics</Bullet>
                  <Bullet condensed>
                    Unlimited Storage for all your events
                  </Bullet>
                </View>
              </View>
            )}

            <View style={{ marginTop: 0 }}>
              <AppTitle
                text="Every Event Includes"
                style={{
                  textAlign: "left",
                  fontSize: 18,
                  marginBottom: 20,
                }}
              />
              <View>
                <Bullet condensed>Unlimited viewers</Bullet>
                <Bullet condensed>Unlimited views</Bullet>
                <Bullet condensed>Full-day HD broadcasting</Bullet>
                <Bullet condensed>White-labeling</Bullet>
                <Bullet condensed>Website embedding</Bullet>
                <Bullet condensed>Unlimited email and chat support</Bullet>
              </View>
            </View>

            <View
              style={{
                marginVertical: 40,
                borderTopWidth: 1,
                borderTopColor: "#ccc",
                paddingTop: 20,
              }}
            >
              <Bullet condensed>
                Pay-per-view ticketing: $1.50 per ticket
              </Bullet>
              <Bullet condensed>
                Non pay-per-view events have a soft limit of 5000 viewer-hours
                (15TB bandwidth). For larger events, additional viewer-hours can
                be purchased at $20 per 1000 (3TB bandwidth).
              </Bullet>
            </View>
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default Plans;
