import { Container, Button, Text, View } from "native-base";
import React, { useState } from "react";
import { ActivityIndicator, Platform, Linking } from "react-native";

import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import AppTitle from "../../shared/AppTitle";
import Bullet from "../../shared/Bullet";
import DashboardContent from "../../shared/DashboardContent";

const environment = process.env.NODE_ENV;

const subs = [
  {
    title: "Monthly",
    price: "$148/mo",
    billingCycle: "Billed monthly",
    savings: "",
    url_test: "https://buy.stripe.com/test_6oEbIZg5E1FB4Ao9AC",
    url_prod: "https://buy.stripe.com/eVa6oU90mbSb9RCcMV",
  },
  {
    title: "Annual",
    price: "$124/mo",
    billingCycle: "$1488 annually",
    savings: "17%",
    url_test: "https://buy.stripe.com/test_dR63ct8Dcdojc2Q6op",
    url_prod: "https://buy.stripe.com/eVadRm90mg8r1l69AI",
  },
];

const Subscribe = ({ navigation, route }) => {
  const [loading, setloading] = useState(false);
  const auth = useAuth();
  const { broadcaster, email } = auth;

  return (
    <Container>
      <AppHeader />
      <DashboardContent>
        {loading && <ActivityIndicator />}
        {!loading && (
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 44,
              paddingHorizontal: 16,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={`Subscribe to EventLive Unlimited`}
              style={{
                textAlign: "left",
                marginBottom: 20,
              }}
            />

            <View style={{ marginVertical: 20 }}>
              <Bullet condensed>Create Unlimited Events</Bullet>
              <Bullet condensed>Unlimited Storage for all your events</Bullet>
              <Bullet condensed>Per-event white-labeling</Bullet>
              <Bullet condensed>Embed on your website</Bullet>
              <Bullet condensed>Advanced Event Analytics</Bullet>
              <Bullet condensed>Unlimited email and chat support</Bullet>
            </View>

            {!!broadcaster && (
              <SubscriptionTable
                subs={subs}
                email={email}
                broadcaster={broadcaster}
                loading={loading}
              />
            )}

            <View
              style={{
                marginVertical: 40,
                borderTopWidth: 1,
                borderTopColor: "#ccc",
                paddingTop: 20,
              }}
            >
              <Bullet condensed>
                Pay-per-view ticketing: $1.50 per ticket
              </Bullet>
              <Bullet condensed>
                Non pay-per-view events have a soft limit of 5000 viewer-hours
                (15TB bandwidth). For larger events, additional viewer-hours can
                be purchased at $20 per 1000 (3TB bandwidth).
              </Bullet>
            </View>
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export const SubscriptionTable = ({ email, broadcaster }) => {
  return (
    <View style={{ width: "100%", marginTop: 20 }}>
      <View
        style={{
          flexDirection: "row",
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
          paddingBottom: 10,
          marginBottom: 10,
        }}
      >
        <Text style={{ flex: 2, fontWeight: "bold" }}>Plan</Text>
        <Text style={{ flex: 2, fontWeight: "bold", textAlign: "right" }}>
          Price
        </Text>
        <Text style={{ flex: 3, fontWeight: "bold", textAlign: "right" }}>
          Billing Cycle
        </Text>
        <Text style={{ flex: 2, fontWeight: "bold", textAlign: "right" }}>
          You Save
        </Text>
        <View style={{ flex: 1, minWidth: 50, marginLeft: 10 }} />
      </View>
      {subs.map((s) => (
        <View
          key={s.title}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Text style={{ flex: 2 }}>{s.title}</Text>
          <Text
            style={{
              flex: 2,
              textAlign: "right",
              fontWeight: "bold",
            }}
          >
            {s.price}
          </Text>
          <Text style={{ flex: 3, textAlign: "right" }}>{s.billingCycle}</Text>
          <Text
            style={{
              flex: 2,
              textAlign: "right",
              color: "green",
              fontWeight: "bold",
            }}
          >
            {s.savings}
          </Text>
          <Button
            style={{
              flex: 1,
              height: 40,
              justifyContent: "center",
              marginLeft: 10,
              paddingHorizontal: 2,
              minWidth: 50,
            }}
            onPress={async () => {
              const url =
                (environment === "production" ? s.url_prod : s.url_test) +
                `?prefilled_email=${email}&client_reference_id=${broadcaster}`;
              if (Platform.OS === "web") {
                document.location.href = url;
              } else {
                await Linking.openURL(url);
              }
            }}
            // disabled={!!loading}
          >
            <Text
              style={{
                color: "white",
                fontSize: 12,
                textAlign: "center",
              }}
            >
              GET
            </Text>
          </Button>
        </View>
      ))}
    </View>
  );
};

export default Subscribe;
