import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { LoadingOverlay } from "./shared/LoadingOverlay";
import { eventLivePageBase, formatPriceWithCurrency } from "./globalUtils";

const buyContext = createContext();

function useProvideBuy() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({
    specialevent50: { currency: "USD", price: 50, country: "US" },
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${eventLivePageBase}api/price`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const { broadcaster } = useAuth();

  const getCurrencyPrice = (productId) => {
    // get https://evt.live/api/price
    //{"country":"PT","currency":"EUR","price":50}
    // slug,

    const data = products[productId] || products.specialevent50;
    let formattedPrice = formatPriceWithCurrency(data.price, data.currency);
    return formattedPrice;
  };

  const getOriginalPrice = (productId) => {
    const data = products[productId] || products.specialevent50;
    return formatPriceWithCurrency(data.originalPrice, data.currency);
  };

  const getPricePerItem = (productId) => {
    const data = products[productId] || products.specialevent50;
    return formatPriceWithCurrency(data.pricePer, data.currency);
  };

  const getDiscountPercent = (productId) => {
    const data = products[productId] || products.specialevent50;
    return Math.round(
      ((data.originalPrice - data.price) / data.originalPrice) * 100
    );
  };

  const getSavingsPercent = (productId, originalProductId) => {
    const data = products[productId] || products.specialevent50;
    const originalData = products[originalProductId] || products.specialevent50;
    const basePrice = originalData.pricePer;
    const price = data.pricePer;
    const savingsPercent = Math.round(((basePrice - price) / basePrice) * 100);
    return savingsPercent;
  };

  const getBuyNowText = (productId, days = 1, days_paid = 0) => {
    const currencyPrice = getCurrencyPrice(productId);
    const daysToPay = days - days_paid;

    if (daysToPay == 1 && days_paid == 0) {
      return `${currencyPrice} One Time`;
    }

    if (days_paid > 0) {
      return `${daysToPay} extra day${
        daysToPay > 1 ? "s" : ""
      }: ${currencyPrice} / day`;
    } else {
      return `${days}-Day Event: ${currencyPrice} / day`;
    }
  };

  // Event handler for when the tab or page becomes visible
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      setLoading(false);
      // Remove the event listener once the loading state has been updated
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener(
        "webkitvisibilitychange",
        handleVisibilityChange
      );
    }
  };

  const purchase = async ({ productId, slug, title, quantity = 1 }) => {
    try {
      setLoading(true);
      if (slug) {
        document.location.href = `${eventLivePageBase}api/unlock/${broadcaster}/${slug}?price=${productId}`;
      } else {
        document.location.href = `${eventLivePageBase}api/buypack/${broadcaster}/?price=${productId}`;
      }
      // Listen for the tab or page to become visible
      document.addEventListener("visibilitychange", handleVisibilityChange);
      document.addEventListener(
        "webkitvisibilitychange",
        handleVisibilityChange
      );
    } catch (err) {
      console.error(err);
    }
  };

  return {
    getCurrencyPrice,
    getOriginalPrice,
    getSavingsPercent,
    getDiscountPercent,
    getPricePerItem,
    purchase,
    loading,
    getBuyNowText,
  };
}

export function ProvideBuy({ children }) {
  const buy = useProvideBuy();
  return (
    <buyContext.Provider value={buy}>
      {children}
      <LoadingOverlay loading={buy.loading} />
    </buyContext.Provider>
  );
}

export const useBuy = () => {
  return useContext(buyContext);
};
