import { Toast, Text, Button, Icon } from "native-base";
import { Ionicons } from "@expo/vector-icons";

import React, { useEffect, useState, useCallback, useRef } from "react";
import * as WebBrowser from "expo-web-browser";
import { Share } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  View,
  useWindowDimensions,
  Platform,
  Linking,
  StyleSheet,
  Pressable,
  NativeEventEmitter,
  NativeModules,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Dropdown } from "react-native-element-dropdown";
import { GetAppStore, GetPlayStore } from "@/components/shared/Buttons";

import AppTitle from "./AppTitle";
import RtmpForm, { UrlToWatch } from "./RtmpForm";
import QRCode from "react-qr-code";
import useClipboardCallback from "@/components/hooks/useClipboardCallback";
import GlobalStyles from "../globalStyle";
import { minDesktopWidth, makeUrl } from "@/components/globalUtils";
import { useAuth } from "@/components/AuthContext";
import { TextLink } from "@/components/shared/TextLink";
import { createTest } from "@/components/http/events";
import { LargeUnlockButton, LiveIndicator } from "@/components/shared/Buttons";
import OnboardingButton from "@/components/shared/OnboardingButton";
import ShareEventButton from "@/components/shared/ShareEventButton";
import { openStreamWindow } from "@/components/Utils";

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: "row",
    flex: 0,
    marginTop: 40,
    marginBottom: 40,
  },
  shareBlock: {
    backgroundColor: "#f0f0f0",
    borderRadius: 10,
    padding: 20,
    marginBottom: 40,
  },
  shareTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  shareText: {
    marginBottom: 20,
    marginTop: 20,
  },
});
const HowToStream = ({ event, relatedEvent }) => {
  const { stream_key } = event;
  const {
    broadcaster,
    profileLoaded,
    prefer_multiple_tests,
    getOpenAppFromWebUrl,
    custom_domain,
  } = useAuth();

  const [testEvent, setTestEvent] = useState(null);
  const [magicUrl, setmagicUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const broadcastLink = `https://eventlive.app.link/streamFor?streamKey=${stream_key}&$ios_nativelink=true`;

  const clipboardCallback = useClipboardCallback();

  const navigation = useNavigation();

  const options = [
    {
      label: "EventLive App (Recommended)",
      value: "app",
    },
    { label: "RTMP", value: "rtmp" },
    { label: "Computer (with OBS)", value: "obs" },
    { label: "GoPro", value: "gopro" },
    { label: "Camera", value: "camera" },
    { label: "YoloBox", value: "yolobox" },
  ];

  const rtmpsServer = "rtmps://go.eventlive.pro:443/live";

  const url = makeUrl(event, custom_domain);

  const linkPress = useCallback(() => {
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else Linking.openURL(url);
  }, [url]);

  const isLive = event?.event_status === "live";

  const openLink = async (url) => {
    await WebBrowser.openBrowserAsync(url);
  };

  const openAppStore = async () => {
    await openLink(
      magicUrl ||
        "https://apps.apple.com/us/app/eventlive-event-live/id1457137042#?platform=iphone"
    );
  };

  const openPlayStore = async () => {
    await openLink(
      "https://play.google.com/store/apps/details?id=pro.eventlive&hl=en&gl=US"
    );
  };

  useEffect(() => {
    if (Platform.OS === "web" && event && event.slug) {
      const fetchMagicUrl = async () => {
        const redir = await getOpenAppFromWebUrl(`/getapp/${event.slug}`);
        setmagicUrl(redir);
      };

      fetchMagicUrl();
    }
  }, [event]);

  useEffect(() => {
    (async () => {
      if (broadcaster) {
        const e = await createTest(broadcaster, false);
        setTestEvent(e);
      }
    })();
  }, [broadcaster]);

  useEffect(() => {
    const loadSelectedOption = async () => {
      try {
        const value = await AsyncStorage.getItem("selectedOption");
        if (value !== null) {
          setSelectedOption(value);
        } else {
          setSelectedOption("app");
        }
      } catch (error) {
        console.error("Error loading selected option:", error);
        setSelectedOption("app");
      }
    };

    loadSelectedOption();
  }, []);

  const handleOptionChange = async (item) => {
    try {
      setSelectedOption(item.value);
      await AsyncStorage.setItem("selectedOption", item.value);
    } catch (error) {
      console.error("Error saving selected option:", error);
    }
  };

  const listenerRef = useRef(null);

  // Effect for adding the event listener
  useEffect(() => {
    const addEventListener = async () => {
      if (
        event &&
        event.stream_key &&
        Platform.OS !== "web" &&
        !listenerRef.current
      ) {
        const emitter =
          Platform.OS === "ios"
            ? new NativeEventEmitter(NativeModules.StartStream)
            : new NativeEventEmitter(NativeModules.StreamModule);

        console.log("adding listener");
        if (listenerRef.current) {
          console.log("Remove current listener");
          listenerRef.current.remove();
        }
        listenerRef.current = emitter.addListener(
          "StreamDone",
          async (data) => {
            console.log("STREAM DONE", data);
            const reviewAsked = await AsyncStorage.getItem("reviewAsked");
            if (!reviewAsked) {
              navigation.navigate("AskReview", { relatedEvent: event });
              await AsyncStorage.setItem("reviewAsked", "true");
            } else {
              console.log("rel-event", relatedEvent);
              if (relatedEvent && !relatedEvent.is_test) {
                navigation.replace("PayWall", {
                  event: relatedEvent,
                  slug: relatedEvent.slug,
                });
              } else {
                navigation.navigate("EventList");
              }
            }
          }
        );
      }
    };

    addEventListener();

    return () => {
      if (listenerRef.current) {
        console.log("removing listener");
        listenerRef.current.remove();
        listenerRef.current = null;
      }
    };
  }, [event?.stream_key, relatedEvent?.slug]);

  const getInstructions = () => {
    switch (selectedOption) {
      case "app":
        return (
          "Download the EventLive App and use this link to go live: " +
          broadcastLink +
          "\n\nLink to watch the event: " +
          url
        );
      case "rtmp":
        return (
          "Use these RTMP settings to go live:\nURL: " +
          rtmpsServer +
          "\nStream Key: " +
          event.stream_key +
          "\n\nLink to watch the event: " +
          url
        );
      case "obs":
        return (
          "Set up OBS with these settings:\nService: Custom\nServer: " +
          rtmpsServer +
          "\nStream Key: " +
          event.stream_key +
          "\n\nLink to watch the event: " +
          url
        );
      case "gopro":
        return (
          "Set up your GoPro with these RTMP settings:\nRTMP URL: " +
          rtmpsServer +
          "\nStream Key: " +
          event.stream_key +
          "\n\nLink to watch the event: " +
          url
        );
      case "camera":
        return (
          "Connect your camera to a computer or encoder and use these RTMP settings:\nURL: " +
          rtmpsServer +
          "\nStream Key: " +
          event.stream_key +
          "\n\nLink to watch the event: " +
          url
        );
      case "yolobox":
        return (
          "Configure your YoloBox with these Custom RTMP settings:\nRTMP URL: " +
          rtmpsServer +
          "\nStream Key: " +
          event.stream_key +
          "\n\nLink to watch the event: " +
          url
        );
      default:
        return "Instructions for going live on EventLive";
    }
  };

  const shareInstructions = async () => {
    const instructions = getInstructions();
    if (Platform.OS === "web") {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "EventLive Streaming Instructions for '" + event.title + "'",
            text: instructions,
          });
        } catch (error) {
          console.error("Error sharing:", error);
          clipboardCallback(instructions);
        }
      } else {
        clipboardCallback(instructions);
      }
    } else {
      try {
        await Share.share({
          message: instructions,
          url: broadcastLink,
        });
      } catch (error) {
        console.error("Error sharing:", error);
        clipboardCallback(instructions);
      }
    }
  };

  if (selectedOption === null) {
    return (
      <View style={{ margin: 20 }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={{ margin: 20 }}>
      <AppTitle
        text={"How would you like to Go Live?"}
        style={{ textAlign: "left", marginBottom: 20 }}
      />
      <Dropdown
        data={options}
        labelField="label"
        valueField="value"
        value={selectedOption}
        onChange={(item) => {
          handleOptionChange(item);
        }}
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: 10,
          borderWidth: 1,
          borderColor: "#ccc",
          borderRadius: 8,
          ...GlobalStyles.appItem,
        }}
        containerStyle={{
          marginBottom: 20,
          padding: 10,
          borderWidth: 1,
          borderColor: "#ccc",
          borderRadius: 8,
          ...GlobalStyles.appItem,
        }}
      />

      {isLive && (
        <View style={{ padding: 20, flex: 1 }}>
          <Text style={styles.title}>You are Live!</Text>

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              maxWidth: 800,
            }}
          >
            <OnboardingButton
              onPress={linkPress}
              text="Watch Live"
              style={{ width: "100%" }}
            />

            <ShareEventButton
              url={url}
              title={event?.title}
              text="Share"
              dark
              bordered
              style={{ width: "100%", alignSelf: "center" }}
            />

            <TextLink
              text={url}
              onPress={linkPress}
              textStyle={{ fontSize: 14, paddingVertical: 5 }}
            />
          </View>
        </View>
      )}
      {!isLive && (
        <>
          {selectedOption === "app" && (
            <>
              <View style={{ marginBottom: 40 }}>
                {Platform.OS !== "web" && (
                  <>
                    <Button
                      onPress={async () => {
                        try {
                          await Share.share({
                            message: event.is_test
                              ? `Watch me run this test live: ${url}`
                              : `Click to Watch '${event.title}' event live: ${url}`,
                            url: url,
                          });
                        } catch (error) {
                          console.error("Error sharing:", error);
                          clipboardCallback(url);
                        }
                      }}
                      rounded
                      primary
                      bordered
                      dark
                      style={{ marginVertical: 20, width: "100%" }}
                    >
                      <Icon name="share-outline" type="Ionicons" size="sm" />
                      <Text>Share Link to Watch</Text>
                    </Button>
                    {/* <UrlToWatch
                      title="Link to Watch Your Live Event"
                      url={url}
                    /> */}
                    <LargeUnlockButton
                      text={event.is_test ? "Test Live" : "Go Live"}
                      onPress={async () => {
                        await openStreamWindow(event);
                      }}
                    />
                  </>
                )}
                {Platform.OS === "web" && (
                  <>
                    <Text style={{ paddingVertical: 10 }}>
                      To go Live, all you need is the EventLive App. EventLive
                      app is currently available on iOS and Android devices.
                      It's recommended to use a tripod for stability. You can
                      start streaming as early as 1 hour before the scheduled
                      time. Tests are free.
                    </Text>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {isMobileUX && magicUrl && (
                        <View>
                          <LargeUnlockButton
                            text="Open our App to Go Live"
                            onPress={() => {
                              Linking.openURL(
                                magicUrl ||
                                  `https://eventlive.app.link/streamFor?streamKey=${event?.stream_key}&$ios_nativelink=true`
                              );
                            }}
                          />
                        </View>
                      )}

                      {!isMobileUX && magicUrl && (
                        <View>
                          <Pressable onPress={openAppStore}>
                            <QRCode
                              value={magicUrl}
                              style={{ maxWidth: 200 }}
                            />
                          </Pressable>
                          <View style={styles.buttonRow}>
                            <GetAppStore
                              onPress={openAppStore}
                              style={{ marginRight: 10 }}
                            />

                            <GetPlayStore onPress={openPlayStore} />
                          </View>
                        </View>
                      )}
                      <UrlToWatch
                        title="Link to Watch Your Live Event"
                        url={url}
                      />
                    </View>
                  </>
                )}
              </View>
            </>
          )}

          {selectedOption === "rtmp" && (
            <>
              <View style={{ marginBottom: 40 }}>
                <Text style={{ paddingVertical: 10 }}>
                  You can go live from computer, certain cameras, encoders,
                  third party apps and professional equipment using RTMP
                  settings below:
                </Text>

                <RtmpForm
                  event={event}
                  test_event={testEvent}
                  custom_domain={custom_domain}
                />
              </View>
            </>
          )}

          {selectedOption === "obs" && (
            <>
              <View style={{ marginBottom: 40 }}>
                <Text style={{ paddingVertical: 10 }}>
                  OBS (Open Broadcaster Software) is a free and open-source
                  software for video recording and live streaming. You can use
                  OBS to stream to EventLive using the RTMP settings provided
                  below.
                </Text>
                <Text style={{ paddingVertical: 10 }}>
                  Follow these steps to set up OBS Studio:
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  1.{" "}
                  <TextLink
                    onPress={() =>
                      Linking.openURL("https://obsproject.com/download")
                    }
                    text="Download and Install OBS Studio"
                  />{" "}
                  on your computer.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  2. Open OBS Studio, skip Auto Configuration Wizard.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  3. Open Settings &gt; Stream &gt; Choose Service &gt; Show
                  All... &gt; EventLive.pro, and enter your stream key.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  4. In Sources add (+) a new Video Capture Device. Name it and
                  in Properties choose your Web Camera and appropriate Preset
                  (for example 1280x720).
                </Text>
                <Text style={{ paddingVertical: 5 }}>5. Start Streaming</Text>

                <RtmpForm
                  event={event}
                  test_event={testEvent}
                  custom_domain={custom_domain}
                />
              </View>
            </>
          )}

          {selectedOption === "camera" && (
            <>
              <View style={{ marginBottom: 40 }}>
                <Text style={{ paddingVertical: 10 }}>
                  You can go live using your camcorder, DSLR, or a webcam. You
                  will need to connect your camera to your computer or an
                  encoder and use the RTMP settings below.
                </Text>

                <Text style={{ paddingVertical: 10 }}>
                  Depending on your camera, you may need to install additional
                  drivers or use a capture card device to convert HDMI signal to
                  USB webcam format.
                </Text>

                <Text style={{ paddingVertical: 10 }}>
                  Read more about{" "}
                  <TextLink
                    onPress={() =>
                      openLink(
                        "https://www.eventlive.pro/blog/live-stream-with-camera"
                      )
                    }
                    text="how to connect your camera to EventLive"
                  />
                  .
                </Text>
                <RtmpForm
                  event={event}
                  test_event={testEvent}
                  custom_domain={custom_domain}
                />
              </View>
            </>
          )}

          {selectedOption === "gopro" && (
            <>
              <View style={{ marginBottom: 40 }}>
                <Text style={{ paddingVertical: 10 }}>
                  GoPro cameras (Hero7, Hero8, Hero9, Hero10, GoPro MAX) can be
                  used to stream to EventLive. Follow these steps to go live:
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  1. Install the Quik app on your phone or tablet.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  2. Open the Quik app and choose "Control Your GoPro".
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  3. Find the "LIVE" button at the bottom of the screen and
                  click "Set Up Live".
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  4. Select RTMP as your streaming platform.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  5. Enter the RTMP URL provided below.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  6. Set resolution to 720p and make sure "Save a copy" is ON.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  7. Click "Set Up Live" and then "Go Live" on the next screen.
                </Text>
                <Text style={{ paddingVertical: 10, fontWeight: "bold" }}>
                  Important: Keep your GoPro close to a WiFi source or your
                  phone if using as a hotspot. Avoid direct sunlight for
                  extended periods to prevent overheating.
                </Text>

                <RtmpForm
                  event={event}
                  test_event={testEvent}
                  custom_domain={custom_domain}
                  displayStyle="url"
                />
              </View>
            </>
          )}

          {selectedOption === "yolobox" && (
            <>
              <View style={{ marginBottom: 40 }}>
                <Text style={{ paddingVertical: 10 }}>
                  YoloBox can be used to stream to EventLive. Follow these steps
                  to go live:
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  1. Go to the Connections tab and hit the + icon.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  2. Choose new Custom RTMP.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  3. YoloBox will offer to send a special link to your email
                  where you can configure the connection.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  4. Follow the link from the email and enter the RTMP details
                  provided below.
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  5. Do not check the Authentication checkbox.
                </Text>
                <Text style={{ paddingVertical: 10, fontWeight: "bold" }}>
                  Important: YoloBox may not allow you to enter connections for
                  multiple events. If you need to delete a connection to add a
                  different event's stream key, swipe the connection and choose
                  Delete.
                </Text>
                <Text style={{ paddingVertical: 10 }}>
                  Troubleshooting tips if your YoloBox stream is laggy:
                </Text>
                <Text style={{ paddingVertical: 5 }}>
                  • Update YoloBox software and firmware, use fewer cameras,
                  switch to CBR bitrate mode and lower if needed, set resolution
                  to 720p with 2000-3000kbps bitrate, choose Direct streaming
                  mode in Settings, avoid overlays and extra features, and
                  prevent device overheating. These adjustments can help improve
                  stream quality and reduce lag.
                </Text>

                <RtmpForm
                  event={event}
                  test_event={testEvent}
                  custom_domain={custom_domain}
                />
              </View>
            </>
          )}

          <View style={styles.shareBlock}>
            <AppTitle
              text={"Delegate going Live"}
              style={{ textAlign: "left" }}
            />
            <Text style={styles.shareText}>
              You can invite a Guest Broadcaster, such as a friend or a
              professional videographer to your event, who can go live without
              needing to sign in or sign up. Only one broadcaster can stream at
              a time.
            </Text>
            {event.is_published && (
              <Button onPress={shareInstructions} rounded primary bordered dark>
                <Icon name="share-outline" type="Ionicons" size="sm" />
                <Text>Share Instructions</Text>
              </Button>
            )}
            {!event.is_published && (
              <Button
                onPress={() =>
                  navigation.navigate("PayWall", { event, slug: event.slug })
                }
                rounded
                primary
                bordered
                dark
              >
                <Text>Buy Event to Share</Text>
              </Button>
            )}
          </View>
        </>
      )}
    </View>
  );
};

export default HowToStream;
